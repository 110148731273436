import "./address-input.css";
import { useCallback, useState } from "react";
import { debounce } from "lodash";
import {
  FlightInfoResponseType,
  searchFlights,
} from "../../services/ApiService";

export type FlightInputType = {
  setFlightInfo: (flightInfo: FlightInfoResponseType) => void;
  sendErrorMessages: (errorMessages: string[]) => void;
  selectedValue?: string;
};

export type FlightSearchPayload = {
  flight_airline: string;
  flight_airport: string;
  flight_date: string; // "2024-08-28"
  flight_number?: string; // "11"
  flight_type_is_arrival: boolean; // false
};

export const FlightNumberInput: React.FC<
  FlightSearchPayload & FlightInputType
> = ({
  setFlightInfo: submitFlightInfo,
  sendErrorMessages,
  selectedValue,
  ...flightData
}) => {
  const [flightNumberValueInput, setFlightNumberValueInput] = useState(
    selectedValue || "",
  );
  const [flightNumbersResponse, setFlightNumbersResponse] = useState<
    FlightInfoResponseType[]
  >([]);

  function handleDebounceFn(inputValue: string) {
    if (inputValue.length >= 1) console.log({ flightData });
    flightData.flight_number = inputValue;
    console.log({ flightData });
    searchFlights(flightData).then((locations) => {
      if ("code" in locations) {
        console.error(locations);
        if (locations.message) sendErrorMessages([locations.message]);
      } else {
        setFlightNumbersResponse(locations);
      }
    });
  }

  const debounceFn = useCallback(
    debounce(handleDebounceFn, 1000, { leading: false, trailing: true }),
    [],
  );

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setFlightNumberValueInput(event.target.value);
    debounceFn(event.target.value);
  }

  const selectAddress = (selectedFlight: FlightInfoResponseType) => {
    setFlightNumberValueInput(selectedFlight.flight_number);
    submitFlightInfo(selectedFlight);
    resetSelection();
  };

  const renderFlightNumberSuggestion = () => {
    if (flightNumbersResponse.length === 0) return null;
    return flightNumbersResponse.map((flight) => {
      return (
        <div
          key={flight.flight_number}
          className="address-suggestion"
          onClick={() => selectAddress(flight)}
        >
          FLIGHT {flight.flight_number} {flight.departure_airport.iata_code} -
          {flight.origin_arrival_airport.iata_code}
        </div>
      );
    });
  };

  const resetSelection = () => {
    setFlightNumberValueInput("");
    setFlightNumbersResponse([]);
  };

  return (
    <div className="address-input-block">
      <input
        type="text"
        className="address-input"
        value={flightNumberValueInput}
        onChange={handleChange}
        placeholder={"Start typing flight number..."}
      />
      {/*<Button*/}
      {/*  type={"primary"}*/}
      {/*  text={"Confirm"}*/}
      {/*  onClick={() =>*/}
      {/*    (parsedAddress && submitAddress(parsedAddress)) || resetSelection()*/}
      {/*  }*/}
      {/*/>*/}
      <div
        className={`address-suggestions ${flightNumbersResponse.length > 0 ? "visible" : "hidden"}`}
      >
        {renderFlightNumberSuggestion()}
      </div>
    </div>
  );
};
