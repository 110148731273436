import { Calendar, DateObject } from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";

import "./date-time-input.css";
import "./date-time-colors.css";

import React, { useState } from "react";
import { Button } from "./Button";
import { BookingFlowState } from "../../services/ChatStateMachine";

export type DateTimeSelectorType = {
  date: Date;
  setDate: (date: Date) => void;
  preventBeforeDate?: Date;
  preventAfterDate?: Date;
  dateOnly?: boolean;
  onSkip?: () => void;
};

export const DateTimeSelector: React.FC<DateTimeSelectorType> = ({
  date,
  setDate,
  preventBeforeDate,
  preventAfterDate,
  dateOnly = false,
  onSkip,
}) => {
  const [selectedValue, setSelectedValue] = useState(new Date(date));
  const handleChange = (value: DateObject) => {
    setSelectedValue(value.toDate());
  };
  console.log("Prevent before date:", preventBeforeDate);
  console.log("Prevent after date:", preventAfterDate);
  return (
    <div className="date-time-input-block">
      <Calendar
        value={selectedValue}
        onChange={handleChange}
        minDate={preventBeforeDate}
        maxDate={preventAfterDate}
        showOtherDays
        className="debc"
        buttons
        // render={(value, onFocus, onChange, separator) => (
        //   <DateTimeInputField
        //     value={value}
        //     openCalendar={onFocus}
        //     onChange={onChange}
        //     onSubmit={setDate}
        //     selectedValue={selectedValue}
        //   />
        // )}
        format={"MM/DD/YYYY hh:mm A"}
        plugins={
          dateOnly
            ? []
            : [<TimePicker position="right" mStep={5} hideSeconds />]
        }
      />
      <div className={"date-time-actions-block"}>
        <Button
          type={"primary"}
          text={"Confirm"}
          width="full"
          onClick={() => setDate(selectedValue)}
        />
        {onSkip && (
          <Button
            key={"skip-flight-info"}
            width="auto"
            onClick={onSkip}
            text="Skip"
            type="secondary"
          />
        )}
      </div>
    </div>
  );
};

// const DateTimeInputField: React.FC<{
//   value: string;
//   openCalendar: () => void;
//   onChange: (e: ChangeEvent<Element>) => void;
//   onSubmit: (date: Date) => void;
//   selectedValue: Date;
// }> = ({ value, openCalendar, selectedValue, onSubmit }) => {
//   return (
//     <div className="date-time-input-block">
//       <input
//         type="text"
//         className="date-time-input"
//         value={value}
//         onFocus={openCalendar}
//         readOnly
//       />
//       <Button
//         type={"primary"}
//         text={"Confirm"}
//         width="full"
//         onClick={() => setDate(selectedValue)}
//       />
//     </div>
//   );
// };
