import React, { FC } from "react";
import { Button } from "../chat-elements/Button";

export type InitialStateComponentProps = {
  botName: string;
  onClick: () => void;
  userDetails: string | null;
  proceedWithBookingButton: () => void;
  downloadReceiptButton: () => void;
  notInterestedButton: () => void;
};

export const InitialStateComponent: FC<InitialStateComponentProps> = (
  props,
) => {
  return (
    <React.Fragment>
      <Button
        type={"primary"}
        text={`Yes, let's book a ride`}
        onClick={props.onClick}
        width="full"
      />
      <Button
        type={"tertiary"}
        text={"I need a receipt"}
        onClick={props.downloadReceiptButton}
        width="full"
      />
    </React.Fragment>
  );
};
