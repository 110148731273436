import React, { useEffect } from "react";
import "./App.css";
import { Chat } from "./ui/Chat";
import { loadGoogleMaps } from "./services/GoogleMapsLoader";

function App() {
  useEffect(() => {
    if (window.location.hostname.startsWith("davel"))
      loadGoogleMaps("AIzaSyAHDamqpBaLnuNi9xPWViBhcKCd5Japirw").then(
        console.log,
      );
    //window.location.hostname.startsWith('davel') ? 'AIzaSyAHDamqpBaLnuNi9xPWViBhcKCd5Japirw' : 'AIzaSyBzwT19VQmVUXsLWARUoNMNrg8hx-BMvRg'
    else
      loadGoogleMaps("AIzaSyBzwT19VQmVUXsLWARUoNMNrg8hx-BMvRg").then(
        console.log,
      );
  }, []);

  const queryParameters = new URLSearchParams(window.location.search);
  const previousState = sessionStorage.getItem("vagent-closed") !== "true";
  if (queryParameters.get("vagent") === "true") {
    return (
      <div className="App">
        <Chat isOpen={previousState} />
      </div>
    );
  }

  return (
    <div className="App">
      <header className="App-header">
        <Chat isOpen={previousState} />
      </header>
    </div>
  );
}

export default App;
