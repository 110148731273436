import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

let vagentDiv = document.getElementById("vagent");
let timeout = 250;

if (!vagentDiv) {
  vagentDiv = document.createElement("div");
  vagentDiv.id = "vagent";
  document.body.appendChild(vagentDiv);
  timeout = 2500;
}

const root = ReactDOM.createRoot(vagentDiv);

const queryParameters = new URLSearchParams(window.location.search);
const uri = window.location.href;

if (queryParameters.get("vagent") === "true" || uri.includes("vagent")) {
  setTimeout(
    () =>
      root.render(
        <React.StrictMode>
          <App />
        </React.StrictMode>,
      ),
    timeout,
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
