const validateIfGoogleIsLoaded = () => {
  return (
    typeof window.google === "object" && typeof window.google.maps === "object"
  );
};

export const loadGoogleMaps = (apiKey: string) => {
  return new Promise((resolve) => {
    if (!validateIfGoogleIsLoaded() && !window.googleMapLoaded) {
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;
      script.async = true;
      script.onload = () => {
        resolve("Maps are loaded");
      };
      document.body.appendChild(script);
      window.googleMapLoaded = true;
    } else resolve("Maps are already loaded");
  });
};
