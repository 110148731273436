import React from "react";
import "./button.css";

export type ButtonType = {
  type: "primary" | "secondary" | "tertiary" | "disabled";
  text: string;
  onClick: () => void;
  width: "full" | "auto";
  className?: string;
  disabled?: boolean;
};

export const Button: React.FC<ButtonType> = ({
  type,
  text,
  onClick,
  width = "auto",
  className = "",
  disabled = false,
}) => {
  return (
    <button
      onClick={onClick}
      className={`vgt-button vgt-button-${type} vgt-button-${width} ${className}`}
      disabled={disabled}
    >
      {text}
    </button>
  );
};
