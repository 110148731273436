import "./address-input.css";
import { useCallback, useState } from "react";
import { debounce } from "lodash";
import { getAirlines } from "../../services/ApiService";

export type AirlineType = {
  code: string;
  name: string;
};

export type AirlineInputType = AirlineType & {
  submitAirline: (address: AirlineType) => void;
};

export const AirlineInput: React.FC<AirlineInputType> = ({
  code,
  name,
  submitAirline,
}) => {
  const [airlineCodeInput, setAirlineCodeInput] = useState("");
  const [airline, setAirline] = useState<AirlineType[]>([]);

  function handleDebounceFn(inputValue: string) {
    if (inputValue.length >= 1) console.log(code);
    getAirlines(inputValue).then(setAirline);
  }

  const debounceFn = useCallback(
    debounce(handleDebounceFn, 500, { leading: false, trailing: true }),
    [],
  );

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setAirlineCodeInput(event.target.value);
    debounceFn(event.target.value);
  }

  const selectAirline = (airline: AirlineType) => {
    submitAirline(airline);
    resetSelection();
  };

  const renderAddressSuggestions = () => {
    return airline.map((airline, index) => {
      return (
        <div
          key={index}
          className="address-suggestion"
          onClick={() => selectAirline(airline)}
        >
          <b>({airline.code})</b> {airline.name}
        </div>
      );
    });
  };

  const resetSelection = () => {
    setAirlineCodeInput("");
    setAirline([]);
  };

  return (
    <div className="address-input-block">
      <input
        type="text"
        className="address-input"
        value={airlineCodeInput}
        onChange={handleChange}
        placeholder={`Input Airline Code or Name`}
      />
      {/*<Button*/}
      {/*  type={"primary"}*/}
      {/*  text={"Confirm"}*/}
      {/*  onClick={() =>*/}
      {/*    (parsedAddress && submitAddress(parsedAddress)) || resetSelection()*/}
      {/*  }*/}
      {/*/>*/}
      <div
        className={`address-suggestions ${airline.length > 0 ? "visible" : "hidden"}`}
      >
        {renderAddressSuggestions()}
      </div>
    </div>
  );
};
